interface ApiConfig {
  api: {
    token: string;
  };
  urls: {
    api_v1: string;
  };
}

export const apiUrlFor = (apiConfig: ApiConfig) => apiConfig.urls.api_v1;
export const adminApiUrlFor = (apiConfig: ApiConfig) =>
  `${apiConfig.urls.api_v1}/admin`;

export function getApiConfig() {
  return Promise.resolve({
    api: {
      token: window.VHX.config.token,
    },
    urls: {
      api_v1: window.VHX.config.api_url,
    },
  });
}

interface FetchOptions {
  method?: string;
  data?: unknown;
}

export async function apiFetch<T>(
  path: string,
  options: FetchOptions = {},
): Promise<T> {
  const { method, data } = options;

  const apiConfig = await getApiConfig();

  const url = /^https?:\/\//.test(path)
    ? path
    : `${apiUrlFor(apiConfig)}${path}`;

  const res = await fetch(url, {
    method: method || 'GET',
    headers: fetchHeaders(apiConfig),
    credentials: 'include',
    body: JSON.stringify(data),
  });

  return await resolveRequest(res);
}

export function adminApiFetch<T>(path: string, options: FetchOptions = {}) {
  return apiFetch<T>(`/admin${path}`, options);
}

export function staffApiFetch<T>(path: string, options: FetchOptions = {}) {
  return apiFetch<T>(`/ott_staff${path}`, options);
}

export const fetchHeaders = (apiConfig: ApiConfig) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${apiConfig.api.token}`,
});

const csrfTokenElement = document.querySelector(
  'meta[name=csrf-token]',
) as HTMLMetaElement;

export const internalHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': csrfTokenElement.content,
  'Content-Type': 'application/json',
};

export const setCSRFToken = (token: string) => {
  csrfTokenElement.content = token;
  internalHeaders['X-CSRF-Token'] = token;
};

export const renewToken = async () => {
  const res = await fetch('/admin/tokens/', {
    method: 'POST',
    headers: internalHeaders,
  });

  const response = await resolveRequest(res);
  if (response.token) {
    window.VHX.config.token = response.token;
  }
};

const getResponseBody = async (res: Response) => {
  try {
    return await res.json();
  } catch (e) {
    // when response doesn't have a body
    return {
      message: res?.ok ? 'success' : 'error',
    };
  }
};

export const resolveRequest = async (res: Response) => {
  try {
    const parsed = await getResponseBody(res);
    if (res?.ok) {
      return Promise.resolve(parsed);
    } else {
      return Promise.reject({
        status: res?.status,
        statusText: res?.statusText,
        message: parsed?.message,
      });
    }
  } catch (e) {
    return Promise.reject({
      status: res?.status,
      statusText: res?.statusText,
    });
  }
};

export const jsonToQuery = (params: { [key: string]: any } = {}) => {
  return Object.keys(params)
    .map((key) => {
      if (params[key] || params[key] === false || params[key] === 0) {
        if (typeof params[key] === 'object') {
          return `${key}=${encodeURIComponent(JSON.stringify(params[key]))}`;
        }
        return `${key}=${encodeURIComponent(params[key])}`;
      }
    })
    .filter((val) => !!val)
    .join('&');
};
